import React from 'react';

export const SidebarData = [

  {
    title: 'Dashboard',
    path: '/dashboard',
    cName: 'nav-text',
  },
  {
    title: 'Contratos',
    path: '/contratos',
    cName: 'nav-text'
  },
  {
    title: 'Clinicas e Hospitais',
    path: '/hospitais',
    cName: 'nav-text'
  },
  {
    title: 'Médicos',
    path: '/medicos',
    cName: 'nav-text'
  }
  
];

export default SidebarData;