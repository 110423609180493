import React from 'react';
import "./medicos.css" ;
import ListMedico from '../../components/ListMedico.js';

function Medicos() {
 document.getElementById("titulodapagina").innerHTML = "Médicos"; 
  
  return (
    
    <div title="Medicos" className='medicos' id="titulodapagina">
      <ListMedico/>
    </div>
  );
}

export default Medicos;