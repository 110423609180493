import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import './Form.css';
import CadastroForm from '../../components/CadastroForm';


function cadastroForm() {
  document.getElementById("titulodapagina").innerHTML = "Dashboard"; 
  return (
    
    <div className='dashboard' id="titulodapagina">
      <CadastroForm/>
   
    </div>
  );
}

export default cadastroForm;




























