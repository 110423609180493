import "./Sidebar.css";
import logo from "../assets/Crookes-logo.png";
import { SidebarData } from './SidebarData';
import { Link } from 'react-router-dom';

const Sidebar =({}) => {
  return(
    <div>
      <div className="sidebar__icon"  onClick="">
        <img className="logo"src={logo} alt="logo" />
      </div>

      <nav className="sidebar">
      
       {SidebarData.map((item, index) => {
          return (
            <li key={index} className="sidebar__nav" >
              <Link to={item.path} activeClassName="active">
                {item.icon}
                <span>{item.title}</span>
              </Link>
            </li>
        
          );
        })} 
      </nav>

    </div>
    



  )

}

export default Sidebar;