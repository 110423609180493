import React,  { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import '../pages/Contratos/contratos.css';
import axios from 'axios';




function ListContrato() {

    const [ListContrato, setListContrato] = useState([]);
  
        useEffect(() => {
          
            
            axios.get('https://localhost:5001/contract/doctor/{doctorid}', {
                headers: { 'Access-Control-Allow-Origin' : '*'
            },
            }).then((response) => {
            setListContrato(response.data);
            });
     },[]);

  return (
    <div className="contratos">
      <div className="titulo">
        <h1>Contrato Médico</h1>
        <h2>{setListContrato.crm}</h2>
        <Link to='/form' className="nav__center--button"> Novo contrato</Link>
      </div>
     <div className="boxarea">
        <span className="tipocontrato">Tipo de contrato</span>
        <div className="box">
          <div className="area1">
            Contrato fixo
          </div>
        <div className="area2">
          <span></span>
          <span>Vigencia 13/10/2022</span>
        </div>
            <div className="area3">
              <span className="item">Valor Emergencia R$ {}</span>
              <span className="item">Valor Pronto socorro R$ {}</span>
              <span className="item">Valor internato R$ {}</span>
              <span className="item">Valor Valor Agendado R$ {}</span>
              <span className="item">Valor Valor fixo R$ {}</span>
              <span className="item">Valor Emergencia R$ {}</span>
              <span className="item">Valor Emergencia R$ {}</span>
              <span className="item">Hospital</span>
              <span className="item">Periodo</span>            
            </div>
          </div>
        <div className="controle">
          <a href="#">editar</a>
          <Link to='/agenda'> Agenda</Link>
        </div>
        </div> 
    </div>
  )}

export default ListContrato;