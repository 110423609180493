import "./Navbar.css";
import comments from "../assets/comment.svg";
import bell from "../assets/bell.svg";
import avatar from "../assets/avatar.png";
import menu from "../assets/bars.svg";
import { Link, Route, Router } from 'react-router-dom';
import SidebarData from './SidebarData';
import Form from '../pages/Form/Form'


const Navbar =({ sidebarOpen, openSidebar}) => {
    return(
        <nav className="navbar">

        
            <div className="nav__right">
                <Link to='#' className='menu-bars'>
                <img className="nav__right--menu-botao"src={menu}  alt="menu" />
                <b className="nav__right--titulo"><span id="titulodapagina"></span></b>
                </Link>
            </div>
          
            <div className="nav__center">
               <Link to='/medicos'> <a href="#"><img className="nav__center--bell"src={bell} width="20" alt="bell" /></a></Link>
                <a href="#"><img className="nav__center--comments"src={comments} width="20" alt="comments" /></a>
               
            </div> 

            <div className="nav__left">
                <img className="nav__left--avatar"src={avatar} width="40" alt="avatar"></img> 
                <p className="nav__left--username">WILL CROOKES</p>   
            </div>   
                
           
        </nav>
    )


}

export default Navbar;