import {useState} from "react";
import Navbar from "./components/Navbar";
import Sidebar from "./components/Sidebar";
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import dashboard from "./pages/Dashboard/dashboard";
import Hospitais from "./pages/Hospitais/hospitais";
import Medicos from "./pages/Medicos/medicos";
import cadastroForm from "./pages/Form/Form";
import agenda from "./pages/Agenda/Agenda";
import ListContrato from "./components/ListContrato";

const App = () => {

  // Base das funções para controlar a abertura e fechamento da barra lateral.
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const openSidebar =() => {
      setSidebarOpen(true);
  }

  const closeSidebar =() => {
    setSidebarOpen(false);

  }

  return (
    <div className="container">
      <Router>
        <Navbar/>
        <Sidebar/>
        <Switch>
            <Route path='/dashboard' exact component={dashboard}/>
            <Route path='/medicos'component={Medicos}/>
            <Route path='/hospitais' data={Hospitais.columns} component={Hospitais}/>
            <Route path='/contratos/:id' data={Hospitais.columns} component={ListContrato}/>
            <Route path='/form'component={cadastroForm}/>
            <Route path='/agenda'component={agenda}/>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
