import React, { useEffect, useState } from 'react';
import MaterialTable from 'material-table';
import axios from 'axios';

export const ListMedico = () => {
  const [medico, setMedico] = useState([]);

  useEffect(() => {

    axios.get('https://localhost:5001/doctor', {
      headers: {
        'Access-Control-Allow-Origin': '*'
      },
    }).then((response) => {
      setMedico(response.data);
    });
  }, []);

  const columns = [
    {

      title: 'Medico',
      field: "name",
      render: ({ name, gender }) => `${gender === 'M' ? 'Dr.' : 'Dra.'} ${name}`
      
    },

    {
      title: "CRM",
      field: "crm",
      render: ({ crm, crmufAbbrev }) => `${crm}-${crmufAbbrev}`
    },
    {
      title: "Telefone",
      field: "phone",
      render: ({ phone, name }) => {
        return <a href={`tel:${phone}`} title={`Ligar para ${name}`}>{phone}</a>
      }
    },
    {
      title: "Email",
      field: "email",
      render: ({ email }) => {
        return <a href={`mailto:${email}`} title={`Enviar e-mail para ${email}`}>{email}</a>
      }
    },
  ];


  const DetailPanel = ({ contacts }) =>
    <div>
      {contacts.map(x => DetailPanelItem(x))}
    </div>

  const DetailPanelItem = ({ hospitalContactName, hospitalContactPhone, hospitalContactType }) =>
    <div>
      <span><strong>Setor:</strong>{hospitalContactType.hospitalContactTypeName}</span>
      <span><strong>Contato:</strong>{hospitalContactName}</span><br></br>
      <span><strong>Telefone:</strong>{hospitalContactPhone}</span>
    </div>

  return (
    <div>

      <MaterialTable
        title=""

        data={medico}
        columns={columns}
        style={{
          backgroundColor: '#fff',
          textJustify: 'auto',

        }}

        options={{
          filtering: false,
          exportButton: false,
          pagination: false,
          pageSize: 20,
          isloading: true,
          headerStyle: {
            backgroundColor: '#fff',
            color: 'black',
            display: 'inline-grid',
            fontSize: '20px',
            margin: "10px",
            alignItems: "center",
          },
          searchFieldAlignment: 'left',
          searchFieldStyle:{
            height:'40px',
            placeContent:'center',
  
            
          },


          rowStyle: {
            backgroundColor: '#f7faff',
            display: 'flex',
            borderRadius: '10px',
            margin: '10px 10px',
            border: '0px',
            transition: 'all 0.3s ease 0s',

          }
        }}
        detailPanel={[
          {
            icon: 'contacts',
            tooltip: 'Contatos',
            render: (client) => {
              return (
                <div
                  style={{
                    backgroundColor: '#fff',
                    transition: 'all 0.3s ease 0s',
                  }}
                >
                  <DetailPanel contacts={client.hospitalContacts || []} />
                </div>

              )
            },
          },


          {
            icon: 'edit',
            tooltip: 'Gerenciar',
            render: rowData => {
              return (
                <div
                  style={{
                    fontSize: 100,
                    textAlign: 'center',
                    color: 'white',
                    backgroundColor: '#fff',
                    height: '100%',

                  }}
                >
                </div>
              )
            },
          },
        ]}
      />




    </div>

  )






  //   return (
  //     <div>

  //       <MaterialTable 

  //         title=" Médicos"
  //         data={medico}
  //         columns={columns}
  //         style={{
  //           textAlign: 'center',
  //           backgroundColor: '#fff',

  //         }}

  //         options={{
  //             exportButton: false,
  //             pagination: false,
  //             headerStyle: {
  //               backgroundColor: '#ccc',
  //               color: '#fff',
  //               display:'inline-grid',

  //             },

  //             rowStyle: {
  //               backgroundColor: '#fff',
  //               display:'flex',
  //               borderRadius:'10px',
  //               margin: '10px',
  //               border: '0px',


  //             }
  //           }}
  //         detailPanel={[
  //           {
  //             icon: 'contacts',
  //             tooltip: 'Contatos',
  //             render: (client) => {
  //               return (
  //                 <div className="Lista__contatos">
  //                   {/* Telefone:{client.hospitalID} Email:{client.hospitalName} {client.cnpj} */}
  //                   <DetailPanel contacts={client.hospitalContacts || []} />
  //                 </div>

  //               )
  //             },
  //           },


  //           {
  //             icon: 'library_adds',
  //             tooltip: 'Contratos',
  //             render: rowData => {
  //               return (
  //                 <div
  //                   style={{
  //                     fontSize: 100,
  //                     textAlign: 'center',
  //                     color: 'white',
  //                     backgroundColor: '#fff'

  //                   }}
  //                 >
  //                   <img src={imagem}></img>
  //                 </div>
  //               )
  //             },
  //           },
  //         ]}
  //       />




  //     </div>

  // )

};

/*
             /* actions={[
                      {
                          icon: 'save',
                          tooltip: 'Save User',
                          onClick: (event, rowData) => {
                              // Do save operation
                          }
                      }
              ]}





          <MaterialTable title= " Clinicas e Hospitais" 
              data = {Hosp}
              columns = {columns}
              actions={[
                  {
                    icon: 'save',
                    tooltip: 'Save User',
                    onClick: (event, rowData) => {
                      // Do save operation
                    }
                  }
                ]}
              options = {{
                  filtering : false,
                  exportButton: true,
                      headerStyle: {
                        backgroundColor: '#ccc',
                        color: '#FFF',
                        columnGap: '10px'
                      },
                      rowStyle: {
                          backgroundColor: '#EEE',
                          
                        }
                    }}
             
              
          />
          */
export default ListMedico;