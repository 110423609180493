import "./dashboard.css";
import React from 'react';

function Dashboard() {
  document.getElementById("titulodapagina").innerHTML = "Dashboard"; 
  return (
    
    <div className='dashboard' id="titulodapagina"> Dashboard a ser desenvolvido
   
    </div>
  );
}

export default Dashboard;