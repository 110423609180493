import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import '../pages/Form/Form.css';



function CadastroForm() {
  document.getElementById("titulodapagina").innerHTML = "Cadastro de Hospital"; 
  return (
    
    <div className='formpage'> 
    <div className="container-form">
        <h1>Cadastro de contrato de Hospital</h1>
        <form className="former">
        <span >Tipo de Contrato</span>
            <div className="form-group__radio">  
                <input className="radio" type="radio" id="distancia" name="tipocontrato" value="Distancia"/>
                <label for="distancia" >Distancia</label><br/>
                <input className="radio" type="radio" id="presencial" name="tipocontrato" value="Presencial"/>
                <label for="presencial" >Presencial</label><br/>
            </div>
            <div className="form-group">
                <label className="form-group__label" for="modalidade">Modalidade</label>
                <select className="select" id="modalidade" name="modalidade">  
                    <option value="Tumografia" className="option">Tumografia</option>
                    <option value="Exame">exame</option>
                    <option value="Emaxe2">extame</option>
                    <option value="Emaxe3">extaaame</option>
                </select> 

                <label for="submodalidade" className="form-group__label">Sub-Modalidade</label>
                <select className="select" id="submodalidade" name="submodalidade">  
                    <option  value="Tumografiacomputadorizada">Tumografia Computadorizada</option>
                    <option value="Tumografia Computadorizada2">Tumografia Computadorizada</option>
                    <option value="Tumografia Computadorizada3">Tumografia Computadorizada</option>
                    <option value="Tumografia Computadorizada4">Tumografia Computadorizada</option>
                </select> 

            </div>
            <div>
            <label for="tipo" className="form-group__label">Tipo</label><br></br> 
                <input type="text" id="tipo"></input>
            </div>
                <div className="form-group__radio">
                <input className="radio" type="radio" id="fixo" name="contrato" value="fixo"/>
                <label  for="fixo" className="form-group__label">fixo</label><br/>
                <input className="radio"type="radio" id="produção" name="contrato" value="Produção"/>
                <label for="produção" className="form-group__label">Produção</label><br/>
                <input className="radio"type="radio" id="Fixo+Produção" name="contrato" value="Fixo+Produção"/>
                <label for="Fixo+Produção" className="form-group__label">Fixo+Produção</label><br/>
                </div>
            <div className="form-group__fields">
                <label for="prazoemergencia" className="form-group__label">Prazo-emergencia</label> 
                <input type="text" id="prazo-emergencia" placeholder="R$"></input>
                <label for="prazoprontosocorro" className="form-group__label">Prazo-Pronto-socorro</label> 
                <input type="text" id="prazoprontosocorro" placeholder="R$"></input>
                <label for="Prazointernado">Prazo-Internado</label> 
                <input type="text" id="Prazointernado" placeholder="R$"></input>
                <label for="Prazoagenda">Prazo-Agenda</label> 
                <input type="text" id="Prazoagenda" placeholder="R$"></input>                 
                <label for="valorfixo">Valor fixo</label> 
                <input type="text" id="valorfixo" placeholder="R$"></input>
                <label for="Estimados">Estimados</label> 
                <input type="text" id="Estimados" placeholder="R$"></input>   
                <label for="Vigencia">Vigencia</label> 
                <input type="text" id="Vigencia" placeholder="R$"></input>               
            </div>
            <label for="Observacoes">Observações</label> 
            <textarea id="observacoes"className="areadetexto" name="observacoes" rows="5" cols="100"></textarea>
            <button className="Enviar">Criar</button>
        </form>

    </div>
    </div>
  );
}

export default CadastroForm;