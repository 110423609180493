import "./Agenda.css";
import React from 'react';
import ListAgenda from "../../components/ListAgenda";
import CardContrato from "../../components/CardContrato";

function Agenda() {
  document.getElementById("titulodapagina").innerHTML = "Agenda"; 
  return (
    
    <div className="containeragenda" > 
    <span className="titulo">Agenda do hospital</span>
    

        <div className="areaagenda1">
          <CardContrato/>
        </div>

        <div className="areaagenda2">
         <ListAgenda/>
        </div>

    </div>
  );
}

export default Agenda;