import React,  { useEffect, useState } from 'react';
import '../pages/Agenda/Agenda.css';
import axios from 'axios';
import FullCalendar from '@fullcalendar/react';
import DayGridplugin  from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';




function ListAgenda() {

    const [ListContrato, setListContrato] = useState([]);
  
        useEffect(() => {
          
            
            axios.get('https://localhost:5001/contract/doctor/f578fc43-0584-4224-86e9-05e5a42da07b', {
                headers: { 'Access-Control-Allow-Origin' : '*'
            },
            }).then((response) => {
            setListContrato(response.data);
            });
     },[]);

  return (

            <FullCalendar 
            plugins={[DayGridplugin, interactionPlugin ]}
            height='100%' 
            initialView="dayGridMonth"
            selectable= "true"
            headerToolbar={{
              left: 'prev,next today',
              center: 'title',
              right: 'dayGridMonth,dayGridWeek,dayGridDay,listWeek'
            }}
            events={[
              { title: 'event 1', date: '2021-05-13' },
              { title: 'event 2', date: '2021-05-15' },
              { title: 'event 2', date: '2020-08-15' },
              { title: 'event 2', date: '2020-08-15' },
              { title: 'event 2', date: '2020-08-15' },
              { title: 'event 2', date: '2020-08-15' },
              { title: 'event 2', date: '2020-08-15' }
               ]}
            dateClick=  {[
            //      alert('Clicked on: ' ),
            //    alert('Coordinates: ' + info.jsEvent.pageX + ',' + info.jsEvent.pageY),
            //     alert('Current view: ' + info.view.type),
            //     // mudar o background color
                 ]}
            />
            

  )}

export default ListAgenda;