import React, { useEffect, useState } from 'react';
import MaterialTable from 'material-table';
import axios from 'axios';
import imagem from '../assets/exemploscontratos.jpg'
import { Link, Route, Router } from 'react-router-dom';
import { render } from '@testing-library/react';
import Hospitais from '../pages/Hospitais/hospitais';

export const ListHospital = () => {
  const [hosp, setHosp] = useState([]);

  useEffect(() => {

    axios.get('https://localhost:5001/hospital', {
      headers: {
        'Access-Control-Allow-Origin': '*'
      },
    }).then((response) => {
      setHosp(response.data);
    });
  }, []);

  const columns = [
    {
      title: 'Hospital',
      field: "name",
      render: ({ name }) => {
        return <Link to={`/contratos/${hosp.hospitalID}`}>{name}</Link>
      }
    },
    {
      title: "Responsável",
      field: "contacts.contactName",
      render: ({ contacts }) => {
        return <div>
          {contacts.map((contact, index) => {
            return <p key={`${contact.contactID}_name`}>{contact.contactName}</p>
          })}
        </div>
      }
    },
    {
      title: "Telefone",
      field: "cnpj",
      header: 'sergui',
      render: ({ contacts }) => {
        return <div>
          {contacts.map((contact) => {

            const { contactID, contactName, contactPhone } = contact;
            return <p key={`${contactID}_phone`}>
              <a href={`tel:${contactPhone}`} title={`Ligue para ${contactName}`}>{contactPhone}</a>
            </p>
          })}
        </div>
      }
    },
    {
      title: "Cnpj",
      field: "cnpj"
    },
  ];


  const DetailPanel = ({ contacts }) =>
    <div>
      {contacts.map(contact =>
        <div className="contatos" key={contact.contactID}>
          {DetailPanelItem(contact)}
        </div>
      )}
    </div>

  const DetailPanelItem = ({ contactEmail, contactName, contactPhone, contactTypeName }) => {
    return (
      <div className="contatoscontainer">
      <table className="table">
        <td>
          <tr><strong>Tipo de contato:</strong> {contactTypeName}</tr>
          <tr><strong>Nome:</strong> {contactName}</tr>
          <tr><strong>Telefone:</strong>
            <a href={`tel:${contactPhone}`} title={`Ligue para o contato ${contactName}`}>{contactPhone}</a>
          </tr>
          <tr><strong>E-mail:</strong>
            <a href={`mailto:${contactEmail}`} title={`Enviar e-mail para ${contactName}`}>{contactEmail}</a>
          </tr>
        </td>
      </table>
      </div>
    )
  }

  return (
    
    <div>
     
      <MaterialTable
        title=""

        data={hosp}
        columns={columns}
        style={{
          backgroundColor: '#fff',
          textJustify: 'auto',

        }}

        options={{
          filtering: false,
          exportButton: false,
          pagination: false,
          pageSize: 20,
          isloading: true,
          searchFieldAlignment: 'left',
          searchFieldStyle:{
            height:'40px',
            transition: 'all 0.3s ease 0s',
            borderBottom: "0px",
            
          },
          headerStyle: {
            backgroundColor: '#fff',
            color: 'black',
            display: 'inline-grid',
            fontSize: '20px',
            margin: "10px",
            alignItems: "center",
          },
          exportButton:{
          

          },

          rowStyle: {
            backgroundColor: '#f7faff',
            display: 'flex',
            borderRadius: '10px',
            margin: '10px 10px',
            border: '0px',
            transition: 'all 0.3s ease 0s',

          }
        }}
        detailPanel={[
          {
            icon: 'contacts',
            tooltip: 'Contatos',
            render: (client) => {
              return (
                <div
                  style={{
                    backgroundColor: '#fff',
                    transition: 'all 0.3s ease 0s',
                  }}
                >
                  <DetailPanel contacts={client.contacts} />
                </div>

              )
            },
          },


          {
            icon: 'edit',
            tooltip: 'Gerenciar',
            render: rowData => {
              return (
                <div
                  style={{
                    fontSize: 100,
                    textAlign: 'center',
                    color: 'white',
                    backgroundColor: '#fff',
                    height: '100%',

                  }}
                >
                  <img src={imagem}></img>
                </div>
              )
            },
          },
        ]}
      />




    </div>

  )

};

/*
             /* actions={[
                      {
                          icon: 'save',
                          tooltip: 'Save User',
                          onClick: (event, rowData) => {
                              // Do save operation
                          }
                      }
              ]}





          <MaterialTable title= " Clinicas e Hospitais" 
              data = {Hosp}
              columns = {columns}
              actions={[
                  {
                    icon: 'save',
                    tooltip: 'Save User',
                    onClick: (event, rowData) => {
                      // Do save operation
                    }
                  }
                ]}
              options = {{
                  filtering : false,
                  exportButton: true,
                      headerStyle: {
                        backgroundColor: '#ccc',
                        color: '#FFF',
                        columnGap: '10px'
                      },
                      rowStyle: {
                          backgroundColor: '#EEE',
                          
                        }
                    }}
             
              
          />
          */

/*render: (client) => {
return (
${client.hospitalName} ${client.cnpj};
)
},
},
*/
export default ListHospital;