import React,  { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import '../pages/Contratos/contratos.css';
import axios from 'axios';
import { Card } from '@material-ui/core';
import { useParams } from 'react-router-dom';




const CardContrato = () => {

  return (
<div>
               
              
  <div className="boxarea">
        <span className="tipocontrato">Tipo de contrato</span>
        <div className="box">
          <div className="area1">
            Contrato fixo
          </div>
        <div className="area2">
          <span></span>
          <span>Vigencia 13/10/2022</span>
        </div>
            <div className="area3">
              <span className="item">Valor Emergencia R$ {}</span>
              <span className="item">Valor Pronto socorro R$ {}</span>
              <span className="item">Valor internato R$ {}</span>
              <span className="item">Valor Valor Agendado R$ {}</span>
              <span className="item">Valor Valor fixo R$ {}</span>
              <span className="item">Valor Emergencia R$ {}</span>
              <span className="item">Valor Emergencia R$ {}</span>
              <span className="item">Hospital</span>
              <span className="item">Periodo</span>            
            </div>
          </div>
 
    </div> 
  
  
  
  
  
  
  
   </div> 


  )}

export default CardContrato;