import React,  { useEffect, useState } from 'react';
import "./hospitais.css";
import ListHospital from '../../components/ListHospital.js';
import { Link, Route, Router } from 'react-router-dom';

function Hospitais() {
  
  setTimeout(() => {

    const title = document.getElementById("titulodapagina");
    if (title) {
      document.getElementById("titulodapagina").innerHTML = "Contratos";
    }
  }, 1000);

  return (
    <div className='hospitais' id="titulodapagina">
            
      <ListHospital />

    </div>
  );
}

export default Hospitais;